import { booleanAttribute, Component, input, numberAttribute, output } from '@angular/core'
import { ModalComponent } from '@shared/components/v2/core/modal/modal.component'
import { ButtonComponent } from '@shared/components/v2/core/button/button.component'
import { ButtonSize } from '@shared/components/v2/core/button/button.enum'
import { SpinnerComponent } from '@shared/components/v2/core/spinner/spinner.component'

@Component({
  selector: 'app-confirm-modal',

  imports: [ModalComponent, ButtonComponent, SpinnerComponent],
  templateUrl: './confirm-modal.component.html',
})
export class ConfirmModalComponent {
  public buttonLabel = input('ตกลง')
  public visible = input(false, {
    transform: booleanAttribute,
  })
  public centered = input(true, {
    transform: booleanAttribute,
  })
  public debounceMs = input(0, {
    transform: numberAttribute,
  })
  public buttonSize = input<ButtonSize>(ButtonSize.Small)
  public closeable = input(false, {
    transform: booleanAttribute,
  })
  public loading = input(false, {
    transform: booleanAttribute,
  })

  public confirmed = output()
  public close = output()

  protected handleConfirm() {
    if (!this.loading()) {
      this.confirmed.emit()
    }
  }

  protected handleClose() {
    this.close.emit()
  }
}
