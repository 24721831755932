<app-modal [visible]="visible()" [closeable]="closeable()" (close)="handleClose()">
  <div class="px-4 pb-4" [class.pt-6]="!closeable()">
    <ng-content />
    <div class="mt-6">
      <app-button fluid [size]="buttonSize()" type="primary" (clicked)="handleConfirm()" [debounceMs]="debounceMs()">
        @if (loading()) {
          <div class="flex justify-center">
            <app-spinner size="20px" borderWidth="3" />
          </div>
        } @else {
          {{ buttonLabel() }}
        }
      </app-button>
    </div>
  </div>
</app-modal>
